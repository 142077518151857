import { getMonoUrl } from "./ClientUtils";

export type TrackLinkProps = {
    event: string;
    feature: string;
    extraParams?: any;
};

export type SendTrackClickFn = (props: TrackLinkProps) => void;

export const getSendTrackEvent = (currentHost: string): SendTrackClickFn =>
  async ({
    event,
    feature,
    extraParams = {}
  }): Promise<void> => {
    const location = (
      feature === 'login_modal' &&
        window && window.location?.search &&
        new URLSearchParams(
          decodeURI(window.location.search)
        ).get('parentLocation')
    )

    const params = { event, feature, ...extraParams, ...(location && { location }) };
    const queryParams = new URLSearchParams(params as Record<string, string>).toString();

    try {
      await window.fetch(`${getMonoUrl(currentHost)}/track?` + queryParams, {
        mode: 'no-cors',
      });
    } catch (error) {
      console.error('Error sending track event:', error);
    }
  };
