import { clientMonoRedirect } from "./ClientUtils";
import { fetchLoginStatus } from "./fetchLoginStatus"

export const fetchLoginStatusAndRedirect = async () => {
  const res = await fetchLoginStatus();
  if (!res.ok) return

  const json = await res.json();
  if (!json?.data?.sub) return

  // The user is already logged in so redirect them back to mono
  clientMonoRedirect();
}
